import React, { useMemo } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { BusinessClientReview } from "../BusinessClientReviews/BusinessClientReviews";

import {
  StyledBusinessContactUs,
  StyledBusinessContactUsCards,
  StyledBusinessContactUsCard,
  StyledBusinessContactUsImage,
  StyledBusinessContactUsTitle,
  StyledBusinessContactUsIframe,
} from "./style";

const BusinessContactUs = ({
  plan, reviews, noShift, formUrl,
}) => {
  const review = useMemo(() => {
    const bigBrandReviews = reviews.filter(review => review.isBigBrand);
    const index = Math.floor(Math.random() * bigBrandReviews.length);

    return bigBrandReviews[index];
  }, [reviews]);

  return (
    <StyledBusinessContactUs noShift={noShift}>
      <StyledBusinessContactUsCards>
        <BusinessContactUsCardPlan plan={plan} review={review} />
        <BusinessContactUsCardForm formUrl={formUrl} />
      </StyledBusinessContactUsCards>
    </StyledBusinessContactUs>
  );
};
const BusinessContactUsCardPlan = ({ plan, review }) => {
  return (
    <StyledBusinessContactUsCard>
      <StyledBusinessContactUsImage src={plan.image} alt='icon' />
      <StyledBusinessContactUsTitle dangerouslySetInnerHTML={{ __html: plan.name }} />
      <BusinessClientReview review={review} />
    </StyledBusinessContactUsCard>
  );
};
const BusinessContactUsCardForm = ({ formUrl }) => {
  return (
    <StyledBusinessContactUsCard id="contactForm" noPadding>
      <StyledBusinessContactUsIframe title="Contact form" scrolling="no" src={formUrl} />
    </StyledBusinessContactUsCard>
  );
};

export default BusinessContactUs;
